import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e6292408"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "footer-wrap m-t-24"
};
import { ref, watch } from 'vue';
import { message } from 'ant-design-vue';
export default {
  __name: 'SelectModal',
  props: {
    modelVisible: {
      default: false,
      type: Boolean
    },
    allList: {
      default: () => [],
      type: Array
    },
    editItem: {
      default: () => {},
      type: Object
    },
    title: {
      default: '国家选择',
      type: String
    },
    readOnly: {
      default: false,
      type: Boolean
    }
  },
  emits: ['update:modelVisible', 'saveData'],
  setup(__props, {
    emit
  }) {
    const props = __props;
    const loading = ref(false),
      targetKeys = ref([]),
      mockData = ref(),
      listStyle = ref({
        width: '50%',
        minHeight: '500px'
      });
    const closeModal = () => {
        targetKeys.value = [];
        emit('update:modelVisible', false);
      },
      filterOption = (inputValue, option) => {
        return option.name.indexOf(inputValue) > -1;
      },
      saveData = async () => {
        var _targetKeys$value;
        if (!((_targetKeys$value = targetKeys.value) !== null && _targetKeys$value !== void 0 && _targetKeys$value.length)) return message.error('请至少选择一个数据！');
        emit('saveData', targetKeys.value);
        closeModal();
      };
    watch(() => props.allList, (n, o) => {
      if (n !== o) {
        mockData.value = n;
      }
    }, {
      immediate: true
    });
    watch(() => props.modelVisible, n => {
      if (n) {
        var _props$editItem;
        if ((_props$editItem = props.editItem) !== null && _props$editItem !== void 0 && _props$editItem.length) {
          var _props$editItem2;
          targetKeys.value = (_props$editItem2 = props.editItem) === null || _props$editItem2 === void 0 ? void 0 : _props$editItem2.map(item => item.regionCode);
        } else {
          var _props$allList;
          targetKeys.value = (_props$allList = props.allList) === null || _props$allList === void 0 ? void 0 : _props$allList.map(item => item.regionCode);
        }
      }
    });
    return (_ctx, _cache) => {
      const _component_a_transfer = _resolveComponent("a-transfer");
      const _component_a_button = _resolveComponent("a-button");
      const _component_a_modal = _resolveComponent("a-modal");
      return _openBlock(), _createBlock(_component_a_modal, {
        centered: "",
        title: __props.title,
        width: "750px",
        open: __props.modelVisible,
        onCancel: closeModal,
        footer: false
      }, {
        default: _withCtx(() => [_createVNode(_component_a_transfer, {
          disabled: __props.readOnly,
          "show-search": "",
          listStyle: listStyle.value,
          "target-keys": targetKeys.value,
          "onUpdate:target-keys": _cache[0] || (_cache[0] = $event => targetKeys.value = $event),
          "data-source": mockData.value,
          "row-key": record => record.regionCode,
          "filter-option": filterOption,
          titles: ['未选择', '已选择'],
          render: item => item.regionName
        }, null, 8, ["disabled", "listStyle", "target-keys", "data-source", "row-key", "render"]), !__props.readOnly ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_a_button, {
          onClick: closeModal
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_a_button, {
          type: "primary",
          loading: loading.value,
          onClick: saveData
        }, {
          default: _withCtx(() => [_createTextVNode("保存")]),
          _: 1
        }, 8, ["loading"])])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["title", "open"]);
    };
  }
};